// Define your theme colors and body background color
$primary-teal: #00a499;
$white: #fff;
$primary-navy: #1c253a;
$cyan: #7ba4db;
$purple: #6C7BCC;
$grey: #999;
$dark-grey: #555;
$danger: #de6e86;
$theme-colors: (
  "primary": $primary-teal,
  "primary-text": $white,
  // Define text color for primary theme
  "secondary": $primary-navy,
  "danger": $danger,
  "purple": $purple,
  "grey": $grey,
  "dark-grey": $dark-grey,
  "cyan": $cyan,
);

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
  7: 2rem,
  8: 1.5rem,
  9: 1rem,
  10: 0.5rem,
);
// Include the Karla and Bitter fonts from Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter&display=swap");

$body-bg: $primary-navy;
$body-color: $primary-navy;

// Define your font variables
$font-family-header: "Karla", serif; // Karla for headers
$font-family-body: "Karla", serif; // Karla Regular for body and secondary Arial

// Import Bootstrap SCSS
@import "bootstrap/scss/bootstrap";

// Apply the font styles
body {
  font-family: $font-family-body;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span {
  font-family: $font-family-header;
}

// Button theme styles
.btn {
  // &-primary {
  //   color: map-get($theme-colors, "primary-text");
  //   // Other styles for .btn-primary can go here
  // }
  border-radius: 12px;
}

.text-cyan {
  color: $cyan;
}

[data-bs-theme="light"] {
  // --bs-body-color: var(--bs-primary-teal);
  // --bs-body-color-rgb: #{to-rgb($primary-teal)};
  --bs-body-bg: var(--bs-white);
  --bs-body-bg-rgb: #{to-rgb($white)};
  // navbar
  --bs-tertiary-bg-rgb: #{to-rgb($white)};
  // navlinks
  --bs-emphasis-color-rgb: #{to-rgb($primary-navy)};


}

.active-teal.active,
.active-teal:active {
  background-color: $primary-teal;
}

.greyed-out {
  opacity: 0.5;
  /* Prevents clicking */
  pointer-events: none;
}

.placeholder-grey {
  &::placeholder {
    color: #888; // Placeholder text color
  }
}

.grey-green-switch.form-switch .form-check-input:not(:checked) {
  background-color: $grey;
}

.grey-green-switch.form-switch .form-check-input:checked {
  background-color: $primary-teal;
}

// Above sets or requires variables (eg. $primary-teal); below could be in own css file if we make enough. 

.bottom-modal .modal-dialog {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  /* Centering the modal dialog */
  margin-right: auto;
  margin-left: auto;
}

.loading-cursor {
  cursor: wait;
  /* Standard loading cursor */
  /* For a custom cursor, you would use: cursor: url('path/to/your/cursor/image.cur'), progress; */
}

/* Chrome, Safari, and newer Edge versions */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  background: $primary-teal; /* Custom thumb color */
}
/* Firefox */
input[type="range"]::-moz-range-thumb {
  background: $primary-teal;
}
/* IE and older Edge versions */
input[type="range"]::-ms-thumb {
  background: $primary-teal;
}
/* This is when it's being dragged */
input[type="range"]:active::-webkit-slider-thumb {
  background: $cyan;
}
input[type="range"]:active::-moz-range-thumb {
  background: $cyan;
}
input[type="range"]:active::-ms-thumb {
  background: $cyan;
}

.primary-tooltip .tooltip-inner {
  background-color: $primary-navy;
  color: $white;
}